<template>
    <div>
        <div v-if="show">
            <div class="card" v-show="operation !== 'detail'">
                <div class="card-header card-header-flex pb-2">
                    <div class="w-100 mt-2">
                        <div class="row">
                            <div class="col-8">
                                <h5 class="mt-3 ml-0 mr-3 mb-2">
                                    <strong>
                                        <template v-if="operation === null">{{$t('title.orders')}}</template>
                                        <template v-else>{{ $t(operationTitle) }}</template>
                                    </strong>
                                </h5>
                            </div>
                            <div class="col-4 text-right">
                                <div v-if="operation === null">
                                    <div class="mt-3">
                                        <!-- Using components -->
                                        <b-input-group class="mt-3">
                                            <b-form-input type="search" class="form-control form-control-sm"
                                                          :placeholder="$t('input.whatAreYouLookingFor')"
                                                          v-on:keyup.enter="handleSearch" v-model="search"></b-form-input>
                                            <b-input-group-append>
                                                <b-button size="sm" :title="$t('button.title.filterRecords')"
                                                          variant="outline-info"
                                                          @click="filters.visible = !filters.visible" v-b-tooltip.hover
                                                          v-if="$global.hasPermission('ordersview')">
                                                    <i class="fa fa-filter"></i>
                                                </b-button>
                                                <b-button size="sm" :title="$t('button.title.resetList')"
                                                          variant="outline-info"
                                                          @click="handleResetClick()" v-b-tooltip.hover>
                                                    <i class="fa fa-refresh"></i>
                                                </b-button>
                                            </b-input-group-append>
                                        </b-input-group>
                                    </div>
                                </div>
                                <div v-else>
                                    <b-button variant="warning" size="sm" class="mt-3"
                                              @click="handleOperationClose()"
                                              v-b-tooltip.hover :title="$t('button.title.cancel')">
                                        <i class="fa fa-arrow-left"></i> {{$t('button.cancel')}}
                                    </b-button>
                                </div>
                            </div>
                        </div><!-- /.row -->
                    </div><!-- /.w-100 -->
                </div><!-- /.card-header -->
                <div class="card-body">
                    <div class="orders-table">
                        <b-table hover responsive
                                 :busy="listingLoading"
                                 :items="dataSource"
                                 :fields="columns"
                                 :sort-by.sync="sortField"
                                 ref="orderTable"
                                 @sort-changed="handleSortChange">
                            <template v-slot:cell(order_id)="{ detailsShowing, item, index, rowSelected}">
                                <div class="position-relative">
                                    <template v-if="rowSelected">
                                        <span aria-hidden="true">&check;</span>
                                    </template>
                                    <a @click="toggleDetails(item)" class="d-inline in-center">
                                        <i :class="[{'fa fa-plus': !detailsShowing}, {'fa fa-minus': detailsShowing}]"></i>
                                    </a>
                                    <span class="indicator-added-inbound-load" v-if="item.load_status < 7 && item.added_in_load_at" :title="$t('title.inboundAdded')" v-b-tooltip.hover></span>
                                    <span class="indicator-added-outbound-load" v-if="item.load_status >= 10 && item.load_status < 17" :title="$t('title.outboundAdded')" v-b-tooltip.hover></span>
                                    {{ (item.order_id ? item.order_id : '') }}
                                </div>
                            </template>
                            <template v-slot:cell(brand_id)="record">
                                <a-avatar shape="square" :size="32" icon="car" :title="record.item.brand ? record.item.brand.title : ''"
                                          v-if="record.item.brand.logo && record.item.brand.logo.download_url"
                                          :src="record.item.brand.logo.system_url+'/thumbnail/32x32/'+record.item.brand.logo.name2"/>
                                <a-avatar shape="square" :size="32" icon="car" :title="record.item.brand ? record.item.brand.title : ''" v-else/>
                            </template>
                            <template v-slot:cell(model_id)="record">
                                {{ (record.item.model ? record.item.model.title : '') }}
                            </template>
                            <template v-slot:cell(updated_at)="record">
                                {{$global.utcDateToLocalDate(record.item.updated_at) }}
                            </template>
                            <template v-slot:cell(additional_services)="record">
                                <span v-if="record.item.additional_services" v-for="(service, index) in record.item.additional_services">
                                    <span :title="service.additional_service.title">{{ index+1 }}. {{service.additional_service.title.length > 25 ? service.additional_service.title.substr(0, 24) + '...' : service.additional_service.title }}</span>
                                    <span v-if="service.is_rejected"> | {{ $t('title.rejected') }}</span>
                                    <span v-else>
                                       {{(!(!service.marked_done_at)) ? ' | ' + $t('title.done') : ' | ' + $t('title.pending') }}
                                     </span>
                                    <br>
                                </span>
                            </template>
                            <template v-slot:cell(date_of_service_order)="record">
                                <span v-if="record.item.additional_services" v-for="(service, index) in record.item.additional_services">
                                    <span :title="service.additional_service.title">{{ index+1 }}. {{ $global.utcDateToLocalDate(service.additional_service.created_at) }}</span>
                                    <br>
                                </span>
                            </template>
                            <template v-slot:cell(status)="record">
                                {{ record.item._status }}
                            </template>
                            <template v-slot:cell(load_status)="record">
                                <ol-status :status="record.item.load_status"></ol-status>
                                <template v-if="record.item.client_affected"><br>
                                    <b-badge v-if="record.item.client_affected_priority === 1" variant="danger" :title="$t('title.clientAffected') + ' | ' + $t('title.high') + (record.item.client_affected_description ? ' | ' + record.item.client_affected_description : '')" v-b-tooltip.hover>{{$t('title.clientAffected')}} </b-badge>
                                    <b-badge v-if="record.item.client_affected_priority === 2" variant="warning" :title="$t('title.clientAffected') + ' | ' + $t('title.medium') + (record.item.client_affected_description ? ' | ' + record.item.client_affected_description : '')" v-b-tooltip.hover>{{$t('title.clientAffected')}}</b-badge>
                                    <b-badge v-if="record.item.client_affected_priority === 3" variant="secondary" :title="$t('title.clientAffected') + ' | ' + $t('title.low') + (record.item.client_affected_description ? ' | ' + record.item.client_affected_description : '')" v-b-tooltip.hover>{{$t('title.clientAffected')}}</b-badge>
                                </template>
                            </template>
                            <template v-slot:row-details="{ item }">
                                <b-card>
                                    <b-list-group flush>
                                        <b-list-group-item><strong>{{$t('column.importerNumber')}}</strong>: {{item.invoice_number_importer}}</b-list-group-item>
                                        <b-list-group-item><strong>{{$t('column.invoiceDateImporter')}}</strong>: {{item.invoice_date_importer}}</b-list-group-item>
                                        <b-list-group-item><strong>{{$t('column.comment')}}</strong>: {{item.comments}}</b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.inboundSupplierCarrier')}}</strong>: {{item.supplier_carrier ? item.supplier_carrier.name: ''}}</b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.inboundSupplierCompound')}}</strong>: {{item.supplier_compound ? item.supplier_compound.name: ''}}</b-list-group-item>
                                        <b-list-group-item><strong>{{$t('title.outboundSupplierCarrier')}}</strong>: {{item.outbound_supplier_carrier ? item.outbound_supplier_carrier.name : ''}}</b-list-group-item>
                                        <b-list-group-item>
                                            <strong>{{$t('title.destinationLocation')}}</strong>:
                                            <template v-if="item.dealer_location">
                                                <span v-if="item.dealer_location.street_no">{{item.dealer_location.street_no}}, </span>
                                                <span v-if="item.dealer_location.street">{{item.dealer_location.street}}, </span>
                                                <span v-if="item.dealer_location.city">{{item.dealer_location.city}}, </span>
                                                <span v-if="item.dealer_location.zip">{{item.dealer_location.zip}}, </span>
                                                <span v-if="item.dealer_location.country">{{item.dealer_location.country}}</span>
                                            </template>
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-card>
                            </template>
                            <template v-slot:cell(action)="record">
                                <a @click="setOperation('detail', record.item.id)"
                                   :title="$t('button.title.detailItem')"
                                   class="ml-1"
                                   v-b-tooltip.hover>
                                    <i class="icmn-info"></i>
                                </a>
                            </template>
                        </b-table><!-- /.b-table -->

                        <div class="clearfix">
                            <div class="float-left ">
                                <b-form-select v-model="pagination.perPage" :options="[5, 10, 50, 100, 1000]" size="sm"></b-form-select>
                            </div>
                            <div class="float-right">
                                <b-pagination
                                    v-model="pagination.current"
                                    :total-rows="pagination.total"
                                    :per-page="pagination.perPage"
                                    :first-text="$t('paginations.first')"
                                    :prev-text="$t('paginations.prev')"
                                    :next-text="$t('paginations.next')"
                                    :last-text="$t('paginations.last')"
                                ></b-pagination>
                            </div><!-- /.pull-right -->
                        </div><!-- /.clearfix -->
                    </div><!-- /.orders-table-->
                    <div class="filter-container">
                        <a-drawer
                            placement="left"
                            :width="'360px'"
                            :wrapStyle="{overflow: 'auto',paddingBottom: '108px'}"
                            :closable="false"
                            @close="filters.visible = !filters.visible"
                            :visible="!operation && filters.visible"
                            :zIndex="10"
                            :title="$t('title.advanceFilters')"
                        >
                            <form @submit.prevent="loadList(listQueryParams, true)" autocomplete="off">
                                <b-row>
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.suppliers')+' *'">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.suppliers"
                                                placeholder=""
                                                v-model="filters.suppliers"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.brands')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.brands"
                                                placeholder=""
                                                v-model="filters.brands"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.models')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.models"
                                                placeholder=""
                                                v-model="filters.models"
                                                @input="handleSelectModelsSelect"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.code')">
                                            <treeselect
                                                :multiple="true"
                                                :options=" _.uniqBy(_.filter(dropdowns.configCode, ({ model_id }) => _.every([_.includes(filters.models, model_id)])),'id')"
                                                placeholder=""
                                                v-model="filters.configCode"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.loadingPoint')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.fromLocations"
                                                placeholder=""
                                                v-model="filters.fromLocations"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.unloadingPoint')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.toLocation"
                                                placeholder=""
                                                v-model="filters.toLocation"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.drivers')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.drivers"
                                                placeholder=""
                                                v-model="filters.drivers"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group
                                            :label="$t('input.vehicles')">
                                            <treeselect
                                                :multiple="true"
                                                :options="dropdowns.vehicles"
                                                placeholder=""
                                                v-model="filters.vehicles"
                                            />
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.fromAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.from_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                    <b-col sm="12">
                                        <b-form-group :label="$t('input.toAddedDate')">
                                            <b-form-datepicker placeholder="" v-model="filters.to_date"
                                                               class="mb-2"></b-form-datepicker>
                                        </b-form-group>
                                    </b-col><!--/b-col-->
                                </b-row>
                                <div class="drawer-footer">
                                    <b-button size='sm' variant="info" @click="filters.visible = !filters.visible"
                                              class="mr-2" :title="$t('button.title.closePanel')" v-b-tooltip.hover>
                                        {{$t('button.close')}}
                                    </b-button>
                                    <b-button size='sm' variant="warning" @click="handleResetFilterClick" class="mr-2"
                                              :title="$t('button.title.resetFilter')" v-b-tooltip.hover>
                                        {{$t('button.reset')}}
                                    </b-button>
                                    <b-button size='sm' variant="primary" button="submit" type="filled"
                                              :title="$t('button.title.filterRecords')" v-b-tooltip.hover>
                                        {{$t('button.apply')}}
                                    </b-button>
                                </div><!-- /.drawer-footer -->
                            </form>
                        </a-drawer>
                    </div><!-- /.filter-container -->
                </div><!-- /.card-body-->
            </div><!-- /.card -->
            <div v-if="operation === 'detail'">
                <detail :handle-close-operation="handleOperationClose"></detail>
            </div>
        </div>
    </div><!--/div-->
</template>
<script>
import ListingMixin from '../../../../util/ListingMixin'
import {mapState} from 'vuex'
import {request} from '../../../../util/Request'
import Treeselect from '@riophae/vue-treeselect'
import Detail from '../../detail'

const FILTER_STATE = {
    visible: false,
    from_date: null,
    to_date: null,
    suppliers: [],
    brands: [],
    models: [],
    configCode: [],
    drivers: [],
    vehicles: [],
    fromLocations:[],
    toLocation:[],
};

const COLUMN_DEFINITION = (self) => [
    {
        label: self.$t('column.orderId'),
        key: 'order_id',
        sortable: true,
        stickyColumn: true,
    },
    {
        label: self.$t('column.brand'),
        key: 'brand_id',
        sortable: true,
    },
    {
        label: self.$t('column.vin'),
        key: 'vin_number',
        sortable: true,
    },
    {
        label: self.$t('column.model'),
        key: 'model_id',
        sortable: true,
    },
    {
        label: self.$t('column.orderedService'),
        key: 'additional_services',
        sortable: true,
    },
    {
        label: self.$t('column.serviceOrderDate'),
        key: 'date_of_service_order',
        sortable: true,
    },
    {
        label: self.$t('column.status'),
        key: 'load_status',
        sortable: true,
    },
    {
        label: self.$t('column.modifiedAt'),
        key: 'updated_at',
        sortable: true,
    },
    {
        label: self.$t('column.action'),
        class: 'text-right',
        key: 'action',
        width: 150,
    }
];

export default {
    mixins: [ListingMixin],
    components: {
        Treeselect,
        Detail
    },
    data() {
        return {
            operationTitle: 'title.orders',
            filters: {...FILTER_STATE},
            listUrl: 'orders/compound/services',
            columns: COLUMN_DEFINITION(this),
            show: true,
            additional_services: [],
            dropdowns: {
                suppliers: [],
                countries: [],
                brands: [],
                models: [],
                configCode: [],
                drivers: [],
                vehicles: [],
                fromLocations:[],
                toLocation:[],
            },
        }
    },
    mounted() {
        this.$title = this.$t('title.orders')
        this.getSuppliersByType();
        this.getCountries();
        this.getBrands();
        this.getModels();
        this.getDrivers();
        this.getTransportVehicles();
        this.getLocations();
    },
    methods: {
        toggleDetails(row) {
            this.$set(row, '_showDetails', ((row._showDetails) ? !row._showDetails : true))
        },
        setOperation(operation = 'add', operationToken = null) {
            this.operationTitle = (operation === 'add' ? 'title.addOrder' : 'title.editOrder')
            this.$router.replace({
                query: Object.assign({},
                    this.$route.query,
                    {
                        ...this.listQueryParams,
                        operation: operation,
                        oToken: operationToken,
                    },
                ),
            }).then(() => {
            }).catch(() => {
            })
        },
        async handleSubmit() {

        },
        async handleEditClick() {

        },
        async getSuppliersByType() {
            try {
                const slug = 'compound';
                const response = await request({
                    url: `/dropdowns/suppliers/${slug}`,
                    method: "post"
                })

                const {data} = response
                this.dropdowns.suppliers = data

            } catch (e) {
                this.dropdowns.suppliers = []
            }
        },
        async getBrands() {
            try {
                const response = await request({
                    url: '/dropdowns/brands',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.brands = data

            } catch (e) {
                this.dropdowns.brands = []
            }
        },
        async getModels() {
            try {
                const response = await request({
                    url: '/dropdowns/models',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.models = data.map(item => ({id: item.id, label: `${item.label}`}))
                this.dropdowns.configCode = data.map(item => ({
                    id: item.version_code,
                    label: item.version_code,
                    model_id: item.id
                }))
            } catch (e) {
                this.dropdowns.models = []
                this.dropdowns.configCode = []
            }
        },
        async getDrivers() {
            try {
                const response = await request({
                    url: '/dropdowns/drivers',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.drivers = data
            } catch (e) {
                this.dropdowns.drivers = []
            }
        },
        async getTransportVehicles() {
            try {
                const response = await request({
                    url: '/dropdowns/transport/vehicles',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.vehicles = data
            } catch (e) {
                this.dropdowns.vehicles = []
            }
        },
        async getLocations() {
            try {
                const response = await request({
                    url: '/dropdowns/locations',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.fromLocations = data
                this.dropdowns.toLocation = data
            } catch (e) {
                this.dropdowns.fromLocations = []
                this.dropdowns.toLocation = []
            }
        },
        async getCountries() {
            try {
                const response = await request({
                    url: '/dropdowns/countries',
                    method: "post"
                })

                const {data} = response
                this.dropdowns.countries = data

            } catch (e) {
                this.dropdowns.countries = []
            }
        },
        handleSelectModelsSelect(changeValue) {
            this.filters.configCode = []
        },
        hasListAccess() {
            return this.$global.hasPermission('ordersview')
        },
        refreshList() {
            this.loadList()
        },
        getExtraParams() {
            return {
                filters: {
                    ...this.filters,
                    from_date: ((this.filters.from_date) ? this.$global.dateToUtcDate(this.filters.from_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                    to_date: ((this.filters.to_date) ? this.$global.dateToUtcDate(this.filters.to_date, 'YYYY-MM-DD', 'YYYY-MM-DD') : ''),
                },
            }
        },
        handleResetFilterClick() {
            this.filters = {...FILTER_STATE}
            this.isFilterApplied = 'reset'
            this.loadList(this.listQueryParams)
        },
    },
    computed: {
        ...mapState([
            'global',
            'settings'
        ]),
    },
}
</script>
<style lang="scss">
.w-80{
    width: 80px;
}

.w-220{
    width: 220px;
}

.in-center{
    position: relative;
    top: 1px;
    margin-right: 8px;
}

@media screen and (max-width: 1379px) {
    .ant-drawer-content-wrapper {
        width: 98% !important;
    }
}

.indicator-added-inbound-load{
    bottom: -4px;
    left: 0;
}

.indicator-added-outbound-load{
    bottom: -4px;
    left: 15px;
}
.indicator-added-priority {
    bottom: -13px;
    left: 0;
}
</style>
